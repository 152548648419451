:root {
  --InQueueColor: #b2ebf2;
  --InQueueColorFaded: hsl(224, 53%, 95%);

  --NotifyCustomerColor: #f06292;
  --NotifyCustomerColorFaded: rgba(226, 119, 176, 0.016);

  /* --AcceptedColor: #41a11a;
  --AcceptedColorFaded: hsl(103, 72%, 95%); */

  --OnHoldColor: hsl(339, 20%, 85%);
  --OnHoldColorFaded: hsl(0, 17%, 73%);

  --DiagnoseColor: #c8e6c9;
  --DiagnoseColorFaded: hsl(88, 50%, 97%);

  --QualityAssuranceColor: #a5d6a7;
  --QualityAssuranceColorFaded: hsl(88, 50%, 95%);

  --InRepairColor: #66bb6a;
  --InRepairColorFaded: hsl(89, 46%, 95%);

  --WaitingForPartsColor: hsl(177, 20%, 76%);
  --WaitingForPartsColorFaded: hsl(177, 62%, 95%);

  --InDeliveryColor: #e9bf78;
  --InDeliveryColorFaded: hsl(38, 72%, 95%);

  --ReadyForPickUpColor: #72aec8;
  --ReadyForPickUpColorFaded: hsl(198, 44%, 95%);

  --DeliveredToCustomerColor: #df9277;
  --DeliveredToCustomerColorFaded: hsl(16, 62%, 95%);

  --DeliveredColor: #64b5f6;
  --DeliveredColorFaded: hsl(207, 89%, 95%);

  --RequestColor: #cbcbcb;
  --RequestColorFaded: hsl(0, 0%, 95%);

  --DeclinedColor: #ff1e4b;
}
.InQueue {
  --rgb: rgb(99, 171, 209);
}
.data-table .table-content tr > td:first-child {
  width: 200px;
  max-width: 200px;
  border-right: 1px solid #f1f5f8 !important;
  border-left: 2.5px solid #d5dce5;
  border-top: 2.5px solid #d5dce5;
  border-bottom: 2.5px solid #d5dce5;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data-table th {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
}

.data-table .table-content tr > td:last-child {
  border-left: 1px solid #f1f5f8 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 2.5px solid #d5dce5;
  border-top: 2.5px solid #d5dce5;
  border-bottom: 2.5px solid #d5dce5;
}

/*  */
.data-table .table-content tr.Request > td {
  border-color: var(--RequestColor);
}
.data-table .table-content tr.Request > td:first-child {
  background-color: var(--RequestColorFaded);
}

/*  */
.data-table .table-content tr.InQueue > td {
  border-color: var(--InQueueColor);
}
.data-table .table-content tr.InQueue > td:first-child {
  background-color: var(--InQueueColorFaded);
}
/*  */
.data-table .table-content tr.Diagnose > td {
  border-color: var(--DiagnoseColor);
}
.data-table .table-content tr.Diagnose > td:first-child {
  background-color: var(--DiagnoseColorFaded);
}
/*  */
.data-table .table-content tr.NotifyCustomer > td {
  border-color: var(--NotifyCustomerColor);
}
.data-table .table-content tr.NotifyCustomer > td:first-child {
  background-color: var(--NotifyCustomerColorFaded);
}
/*  */
.data-table .table-content tr.OnHold > td {
  border-color: var(--OnHoldColor);
}
.data-table .table-content tr.OnHold > td:first-child {
  background-color: var(--OnHoldColorFaded);
}
/*  */
.data-table .table-content tr.InRepair > td {
  border-color: var(--InRepairColor);
}
.data-table .table-content tr.InRepair > td:first-child {
  background-color: var(--InRepairColorFaded);
}
/*  */
.data-table .table-content tr.WaitingForParts > td {
  border-color: var(--WaitingForPartsColor);
}
.data-table .table-content tr.WaitingForParts > td:first-child {
  background-color: var(--WaitingForPartsColorFaded);
}
/*  */
.data-table .table-content tr.InDelivery > td {
  border-color: var(--InDeliveryColor);
}
.data-table .table-content tr.InDelivery > td:first-child {
  background-color: var(--InDeliveryColorFaded);
}

/*  */
.data-table .table-content tr.QualityAssurance > td {
  border-color: var(--QualityAssuranceColor);
}
.data-table .table-content tr.QualityAssurance > td:first-child {
  background-color: var(--QualityAssuranceColorFaded);
}
/*  */
.data-table .table-content tr.ReadyForPickUp > td {
  border-color: var(--ReadyForPickUpColor);
}
.data-table .table-content tr.ReadyForPickUp > td:first-child {
  background-color: var(--ReadyForPickUpColorFaded);
}
/*  */
.data-table .table-content tr.DeliveredToCustomer > td {
  border-color: var(--DeliveredToCustomerColor);
}
.data-table .table-content tr.DeliveredToCustomer > td:first-child {
  background-color: var(--DeliveredToCustomerColorFaded);
}

/*  */
.data-table .table-content tr > td:not(:first-child):not(:last-child) {
  border-left: 1px solid #f1f5f8 !important;
  border-right: 1px solid #f1f5f8 !important;
}
.data-table .table-content tr > td:not(:first-child):not(:last-child) {
  border-left: 1px solid #f1f5f8 !important;
  border-right: 1px solid #f1f5f8 !important;
}

.data-table .table-content tr > td {
  border-top: 2.5px solid #d5dce5;
  border-bottom: 2.5px solid #d5dce5;
}

.data-table .table-content tr > th {
  background-color: transparent;
  color: #8b98ab;
  padding-bottom: 1px !important;
  padding-top: 2px !important;
  margin: 0px !important;
}
.data-table tbody.table-content > tr > td {
  background-color: #ffff;
}

.data-table {
  border-collapse: separate;
  border-spacing: 0rem 0.5rem !important;
}

.data-table .loading td {
  height: 0px;
  border: 0px solid !important ;
}

.data-table .table-content tr:hover td {
  background-color: rgba(255, 255, 255, 0.689);
}

.clickable {
  cursor: pointer;
}

.entryDetailsCell {
  min-width: 170px;
}
.cellContent {
  max-height: 100px;
  min-height: 100px;
}

/* .entryCell {
  width: 134px;
}
.deviceCell {
  width: 150px;
}
.malfunctionCell {
  width: 400px;
}
.budgetCell {
  min-width: 240px;
  max-width: 400px;
}
.statusCell {
  width: 200px;
}
.commentCell {
  width: 300px;
}

@media (max-width: 1024px) {
  .deviceCell {
    width: 150px;
  }
  .commentCell {
    width: 200px;
  }
  .malfunctionCell {
    width: 150px;
  }
}
@media (max-width: 1680) and (min-width: 1025px) {
  .malfunctionCell {
    width: 200px;
  }
} */

/* .status{
  color:white;
}
.status.Repaired {
  background-color:  var(--RepairedColor);
}
.status.Irreparable {
  background-color: #56423D;
}
.status.Irreproducible {
  background-color: #56423D;
}
.status.Accepted {
  background-color: var(--AcceptedColor);
}
.status.Declined {
  background-color: #FF1E4B;
}

.status.NotifyCustomer {
  background-color: #EC4D96;
}
.status.InRepair {
  color:black;
  background-color: rgba(var(--InRepairColor),0.4);
  border: solid 1px #77a3e6
}
.status.ClientDecision {
  background-color: #56423D;
}
.status.Request{
  background-color: var(--RequestColor);
}

.status > * {
  color:inherit;
} */

/* .status:hover{
  filter: grayscale(15%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

} */

/* NEW WINDOW TABLE */

.InQueue {
  --rgb: rgb(99, 171, 209);
}

.first {
  border-right: 1px solid #bbc2cd !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/*  */
.Request {
  border: 2.5px solid var(--RequestColor);
}
.Request .first {
  background-color: var(--RequestColorFaded);
}

/*  */
.InQueue {
  border: 2.5px solid var(--InQueueColor);
}
.InQueue .first {
  background-color: var(--InQueueColorFaded);
}

/*  */
.Diagnose {
  border: 2.5px solid var(--DiagnoseColor);
}
.Diagnose .first {
  background-color: var(--DiagnoseColorFaded);
}

/*  */
.NotifyCustomer {
  border: 2.5px solid var(--NotifyCustomerColor);
}
.NotifyCustomer .first {
  background-color: var(--NotifyCustomerColorFaded);
}

/*  */
.OnHold {
  border: 2.5px solid var(--OnHoldColor);
}
.OnHold .first {
  background-color: var(--OnHoldColorFaded);
}

/*  */
.InRepair {
  border: 2.5px solid var(--InRepairColor);
}
.InRepair .first {
  background-color: var(--InRepairColorFaded);
}

/*  */
.WaitingForParts {
  border: 2.5px solid var(--WaitingForPartsColor);
}
.WaitingForParts .first {
  background-color: var(--WaitingForPartsColorFaded);
}

/*  */
.InDelivery {
  border: 2.5px solid var(--InDeliveryColor);
}
.InDelivery .first {
  background-color: var(--InDeliveryColorFaded);
}

/*  */
.QualityAssurance {
  border: 2.5px solid var(--QualityAssuranceColor);
}
.QualityAssurance .first {
  background-color: var(--QualityAssuranceColorFaded);
}

/*  */
.ReadyForPickUp {
  border: 2.5px solid var(--ReadyForPickUpColor);
}
.ReadyForPickUp .first {
  background-color: var(--ReadyForPickUpColorFaded);
}

/*  */
.DeliveredToCustomer {
  border: 2.5px solid var(--DeliveredToCustomerColor);
}
.DeliveredToCustomer .first {
  background-color: var(--DeliveredToCustomerColorFaded);
}

.Delivered {
  border: 2.5px solid var(--DeliveredColor);
}

.Delivered .first {
  background-color: var(--DeliveredColorFaded);
}

.OrderParts {
  border: 2.5px solid var(--DeliveredColor);
}

.OrderParts .first {
  background-color: var(--DeliveredColorFaded);
}

.header {
}

.flex-table {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, 20%); */
  /* grid-template-rows: 100% auto; */
  min-width: 500px;
  flex-direction: column;

  border-radius: 10px;
  /* transition: 0.5s; */
  /* height: 90%; */
}
.row {
  border-radius: 10px;
  height: 90%;
  width: 100%;
}

.flex-row {
  padding: 0.5em 0.5em;
  border-right: 1px solid #bbc2cd !important;
}

.rowspan {
  /* display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 100%; */
}

.column {
  /* width: 100%; */
  /* padding: 0; */
}
.column .flex-row {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, 33.3%);
  grid-template-rows: 100% 100% 100%; */
  /* width: 100%; */
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}
.column .flex-row:hover {
  transition: 500ms;
}

.flex-cell {
  /* text-align: center; */
  padding: 0.5em 0.5em;
  border-right: solid 1px #d9d9d9;
}

.table-content {
  /* display: grid; */
  /* align-content: center; */
  flex: auto;
  padding: 10px;
  background-color: white;
}
.table-content:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.no-scrollbars {
  width: initial;
}
@media (max-width: 950px) {
  .no-scrollbars {
    overflow: hidden;
    width: 103%;
  }
}

/* .orderColumn {
  max-width: 250px;
  min-width: 250px;
}

.orderColumnCell {
  width: 100%;
}

.deviceColumn {
  max-width: 250px;
  min-width: 200px;
  justify-content: center;
}
.malfunctionColumn {
  max-width: 250px;
  min-width: 250px;
}
.statusColumn {
  max-width: 250px;
  min-width: 250px;
  width: 100%;
}

.statusColumnCell {
  width: 100%;
} */
.servicesColumn {
  min-width: 250px;
  /* width: 100%; */
  /* flex: 1 1 auto; */
}
.row.clickable:hover > .flex-row {
  background-color: rgba(250, 250, 255, 1);
  background-blend-mode: screen;
}

/* @media (max-width: 600px) {
  .orderColumn {
    max-width: 200px;
    min-width: 200px;
  }
  .deviceColumn {
    max-width: 200px;
    min-width: 160px;
    justify-content: center;
  }
  .malfunctionColumn {
    max-width: 150px;
    min-width: 150px;
  }
  .statusColumn {
    max-width: 150px;
    min-width: 150px;
    width: 100%;
  }
  
  .statusColumnCell {
    width: 100%;
  }
  .servicesColumn {
    min-width: 300px;
    max-width: 300px;

    width: 100%;
  }
} */
