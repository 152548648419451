.sectionGroup > :first-child > .section {
  /* background-color: gray; */
}

.sectionGroup > .section {
  padding-top: 15px;
  padding-bottom: 15px;
  /* background-color: blanchedalmond; */
  flex: 1;
}
.sectionGroup > .section-sm {
  padding-top: 5px;
  padding-bottom:  5px;
  /* background-color: blanchedalmond; */
  flex: 1;
}
.sectionGroup > :last-child > .section {
  /* background-color: gray; */
  padding-top: 15px;
  padding-bottom: 15px;
}
.sectionGroup {
  display: flex;
  flex-direction: column;
}

.section, .section-sm {
  padding-right: 15px;
  padding-left: 15px;
}
