.service-table {
  border-collapse: separate!important;
  border-spacing: 0rem 0.2rem !important;
}

.service-table > tbody > tr {
  background-color: #f1f9fd;
  margin-top: 5px;
}

.service-table > thead > tr > th {
  color: #8b98ab;
}

.service-table tbody > tr > td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.service-table tbody > tr > td {
  border: none;
}

.service-table tbody > tr > td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 50px;
}

.service-table tbody > tr > td:last-child(-1) {

  width: 100px!important;
}
