@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .mainWrapper {
  display: flex;
  flex-flow: row nowrap;
}
.mainContainer {
  flex: 1 1 0%;
  max-width: 100%;
  padding: 64px 0;
  margin-top: 64px;
} */

@media only screen and (min-width: 736px) {
  main {
    display: flex;
    flex-direction: column;
  }
}

main {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
}
@media screen and (min-width: 1024px) {
  .content {
    display: flex;
  }
}
@media screen and (max-width: 1023px) {
  .content {
    display: block;
  }
}

.navPusher,
.navPusher > :first-child {
  flex-grow: 1;
}

@media screen and (min-width: 1024px) {
  .navPusher {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px);
    /* padding-top: 50px; */
  }
}
.navPusher {
  left: 0;
  min-height: 100%;
  /* padding-top: 64px; */
  position: relative;
  z-index: 99;
}

/* DOC MAIN WRAPPER */
.docMainWrapper > * {
  margin: 0 0px;
}
@media only screen and (min-width: 1024px) {
  .docMainWrapper {
    display: flex;
    /* flex-flow: row nowrap; */
  }
}

@supports (position: sticky) {
  @media only screen and (min-width: 1024px) {
    .separateOnPageNav .headerWrapper.wrapper,
    .separateOnPageNav .wrapper {
      /* max-width: 1400px; */
    }
  }
}

@media only screen and (min-width: 1024px) {
  .docMainWrapper {
    width: 100%;
  }
}

@supports (position: sticky) {
  @media only screen and (min-width: 1024px) {
    .separateOnPageNav.sideNavVisible .navPusher .mainContainer {
      flex: 1 auto;
      max-width: 100%;
      min-width: 0;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .docsNavContainer {
    max-width: 500px;
    min-width: 300px;
    flex: 0;
    height: calc(100vh - 125px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }

  .leftPanelContainer {
    max-width: 500px;
    min-width: 500px;
    flex: 0;
    height: calc(100vh - 125px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }
  .leftPanelContainerNoToolbar {
    max-width: 500px;
    min-width: 500px;
    flex: 0;
    height: calc(100vh - 64px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }
  .invoiceNavContainer {
    max-width: 550px;
    min-width: 400px;
    flex: 0;
    height: calc(100vh - 125px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }

  .splitLeft {
    max-width: 50%;
    min-width: 50%;
    flex: 0;
    height: calc(100vh - 124px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }

  .splitRight {
    max-width: 50%;
    min-width: 50%;
    flex: 0;
    height: calc(100vh - 124px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .ticketContainer {
    flex: 2;
    height: calc(100vh - 124px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }
  .ticketContainerNoToolbar {
    flex: 2;
    height: calc(100vh - 64px);
    position: sticky;
    overflow-y: auto;
    top: 50px;
  }
}
.headerPush {
  margin-top: 64px;
}
.btn-user {
  color: white;
}
.separateOnPageNav.sideNavVisible .navPusher .mainContainer {
  flex: 1 auto;
  max-width: 100%;
  min-width: 0;
}

body {
  /* background-color: #F9FCFE!important; */
  /* background-color: black!important; */
}

main {
  /* background-color: inherit; */
}

.invoiceTableCellText {
  font-size: 3mm !important;
}
.invoiceText {
  font-size: 3mm !important;
  line-height: 1;
}
h2.invoiceText {
  font-size: 3.5mm !important;
  line-height: 1;
}

.invoiceTableHeadText {
  font-size: 3mm !important;
  font-weight: 600;
  line-height: 1.1;
}
.invoiceTable > thead > tr > th {
  padding: 0px 10px !important;
  background-color: rgb(233, 238, 248);
}

.invoiceTable > tbody > tr > td {
  padding: 0px 5px !important;
}

.bold {
  font-weight: bold !important;
}

.editableRow {
  /* padding: 2px; */
}

.editableTypography {
  border: 1px solid transparent;
  min-width: 25px;
  padding: 1px;
}

.editableTypography:hover {
  background-color: transparent;
  outline: 0px solid;
  border: 1px solid gray;
  border-radius: 2px;
}
.editableTypography:focus {
  background-color: white;
  outline: 0px solid;
  border: 1px solid gray;
  border-radius: 2px;
}

.editableRow:hover {
  background-color: aqua;
  /* border-radius: 5px; */
  /* padding: 2px; */
}
.editableRow:focus-within {
  background-color: transparent;
  /* border-radius: 5px; */
  /* padding: 2px; */
}

.editableRow:hover .textareaAsTypography > * > textarea,
.editableRow:hover .textareaAsTypography > * > input {
  border: 1px solid darkgray;
}
.textareaAsTypography > * > textarea,
.textareaAsTypography > * > input {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.textareaAsTypography:hover > * > textarea,
.textareaAsTypography:hover > * > input {
  border: 1px solid darkgray;
}
.textareaAsTypography > * > textarea:focus,
.textareaAsTypography > * > input:focus {
  border: 1px solid black;
}

.simplifiedInvoiceTable > thead > tr > td > p,
.simplifiedInvoiceTable > tfoot > tr:first-child td p {
  font-size: 3.2mm;
  font-weight: 800;
}
.simplifiedInvoiceTable > thead tr td {
  border-bottom: 1px solid;
}
.simplifiedInvoiceTable > tbody > tr:not(:first-child) td {
  border-top: 0.5px solid;
}

.simplifiedInvoiceTable > tbody > tr > td p {
  font-size: 3mm;
}
.simplifiedInvoiceTable > tfoot > tr:first-child td {
  border-top: 1px solid;
}
.simplifiedInvoiceTable > tfoot > tr > td > p {
  font-size: 3mm;
  font-weight: 800;
}
.centerInput > * > textarea {
  text-align: center;
}
.centerInput > * > input {
  text-align: center;
}
@media print {
  .no-print {
    display: none !important;
  }
}

#tidio-chat-iframe {
  margin-left: -1.2em !important;
}
/* Container for the borders */
.developer-border {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none; /* Allows interaction with underlying content */
  z-index: 100000;
}

/* Common styles for all border sides */
.developer-border .top,
.developer-border .bottom {
  position: absolute;
  background: linear-gradient(45deg, red, yellow, red);
  background-size: 400% 400%;
  animation: borderAnimation 3s linear infinite;
  pointer-events: none;
}

/* Specific styles for each side */
.developer-border .top,
.developer-border .bottom {
  height: 5px; /* Border thickness */
  width: 100%;
}

.developer-border .top {
  top: 0;
  left: 0;
}

.developer-border .bottom {
  bottom: 0;
  left: 0;
}

/* Keyframes for animating the gradient background */
@keyframes borderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
