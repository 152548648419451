.react-pattern-lock__pattern-wrapper
  .react-pattern-lock__point-wrapper
  .react-pattern-lock__point
  .react-pattern-lock__point-inner {
  background: #5072cb !important;
}

.react-pattern-lock__pattern-wrapper
  .react-pattern-lock__connector-wrapper
  .react-pattern-lock__connector {
  background: #5072cb !important;
}
