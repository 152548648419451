.logo {
  /* height: 36px; */
  height: 100%;
  max-width: 100%;

  object-fit: scale-down;
}

.logo-invoice {
  /* height: 36px; */
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: scale-down;

}

.ticket {
  filter: grayscale();
}

@media print{.paper{
  box-shadow: none!important;
  border-radius: 0!important;
}
}