/* Desktop */
@media (min-width: 1281px) {
  
    .login-card{
        width: 25rem;
        
    }
  }

  @media (min-width: 768px) and (max-width: 1280px) {
  
    .login-card{
        width: 22rem;
        
    }
  }


  @media (min-width: 481px) and (max-width: 767px) {
  
    .login-card{
        width: 18rem;
        
    }
    
  }
