#invoice-a4 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

#invoice-a4.invoice {
  width: 210mm;
  height: 296mm;
  padding: 8mm;
  margin: auto;
  /* border: 1px solid #ddd; */
  background-color: #fff;
  box-sizing: border-box; /* Ensure padding is included in width/height */
  overflow: hidden; /* Prevent content from spilling out */
}

#invoice-a4 h1,
#invoice-a4 h2,
#invoice-a4 h3,
#invoice-a4 h4,
#invoice-a4 h5,
#invoice-a4 h6 {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0 0 10px;
  font-weight: 700;
}

#invoice-a4 h1,
.invoice-h1 {
  font-size: 32px;
}

#invoice-a4 h2,
.invoice-h2 {
  font-size: 28px;
}

#invoice-a4 h3,
.invoice-h3 {
  font-size: 24px;
}

#invoice-a4 h4,
.invoice-h4 {
  font-size: 20px;
}

#invoice-a4 h5,
.invoice-h5 {
  font-size: 16px;
}

#invoice-a4 h6,
.invoice-h6 {
  font-size: 14px;
}

#invoice-a4 p,
.invoice-p {
  margin: 0 0 0px;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
}

#invoice-a4 .invoice {
  width: 210mm;
  height: 297mm;
  padding: 20mm;
  margin: auto;
  border: 1px solid #ddd;
  background-color: #fff;
}

#invoice-a4 .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#invoice-a4 .logo img {
  max-width: 170px;
  max-height: 80px;
}

#invoice-a4 .invoice-info {
  text-align: right;
}

#invoice-a4 .details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

#invoice-a4 .details div {
  width: 100%;
  padding-right: 10px;
}

#invoice-a4 table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#invoice-a4 table th {
  background-color: #f4f4f4;
  white-space: nowrap; /* Prevent text wrapping */
  font-size: 12px; /* Reduce the font size */
  text-align: center; /* Center align the text */
}

#invoice-a4 table th,
#invoice-a4 table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#invoice-a4 .totals {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align to the right */
  gap: 0px;
}

#invoice-a4 .total-row {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure it takes full width */
}

#invoice-a4 .totals {
  width: 35%;
}

#invoice-a4 .note {
  width: 40%;
  margin-top: 20px;
  /* font-size: 0.9em; */
  color: #666;
}

#invoice-a4 .included {
  background-color: #e0f7fa;
}

#invoice-a4 .excluded {
  background-color: #fbe9e7;
}

.pre-wrap {
  white-space: pre-wrap;
}

.editable-text {
  cursor: pointer;
  border: transparent 1px solid;
  /* width: 100%; Ensure it takes full width */
}

.editable-text:hover {
  background-color: #b4e1f7;
  border: #666 1px solid;
}

.editable-input {
  font-size: inherit;
  font-family: inherit;
  border: none;
  background: none;
  /* width: 100%; Ensure it takes full width */
  padding: 0;
  margin: 0;
  outline: none;
  white-space: pre-wrap;
  display: inline-block;
  resize: none; /* Prevent resizing */
  /* background-color: #e1efff; */
}

.common-text-style {
  font-family: inherit; /* Ensure the font family is the same */
  white-space: pre-wrap; /* Ensure text wraps the same way */
}

.placeholder {
  color: #999;
}

.inline-block {
  display: inline-block;
}

.invoice-row {
  display: flex;
  align-items: center;
}

.invoice-container {
  display: flex;
  justify-content: space-between;
}
